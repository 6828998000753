import React from 'react';
import { useModalControls } from '@/shared/hooks';
import { CookiesNames } from '@/shared/constants';
import { getCookie, setCookie } from '@/shared/utils';
import Button from '@/shared/ui/Button';
import Checkbox from '@/shared/ui/Checkbox';
import Modal from '@/shared/ui/Modal';
import {
  ButtonsWrapper,
  CustomizeSettingsButton,
  GDPRContent,
  PrivacySettingsItem,
  PrivacySettingsItemContent,
  PrivacySettingsItemDescription,
  PrivacySettingsItemsList,
  PrivacySettingsItemTitle,
  PrivacySettingsModalContent,
  Wrapper,
} from './styled';

const texts = {
  analytics_cookies: {
    title: 'Analytics Cookies',
    description: 'For measuring website traffic and sources.\n',
  },
  other_cookies: {
    title: 'Other Trackers',
    description: 'For other tracking technologies like pixels or local storage.',
  },
};

function GDPR<T extends { [k: string]: boolean }>({
  onSubmit = () => {},
  initialSettings,
}: {
  onSubmit?: (props: T) => void;
  initialSettings: T;
}) {
  const [isOpen, setIsOpen] = React.useState(!getCookie(CookiesNames.Consent));
  const [privacySettings, setPrivacySettings] = React.useState<T>(initialSettings);
  const { isOpen: isSettingsModalOpen, openModal, closeModal } = useModalControls(false, { disableBodyScroll: true });

  const updatePrivacySettings = (config: Partial<T>) => {
    setPrivacySettings((prev) => ({ ...prev, ...config }));
  };

  const handleCookieConsent = (config: T) => {
    setCookie(
      CookiesNames.Consent,
      new URLSearchParams(Object.entries(config).map(([key, val]) => [key, String(Number(val))])).toString(),
      30
    );
    setIsOpen(false);
    onSubmit(config);
  };

  if (!isOpen) {
    return null;
  }

  return (
    <>
      <Modal
        title="Change Your Privacy Settings"
        content={
          <PrivacySettingsModalContent>
            <PrivacySettingsItemsList>
              {Object.keys(privacySettings).map((key) => (
                <PrivacySettingsItem key={key}>
                  <Checkbox
                    checked={privacySettings[key]}
                    onChange={(e) => updatePrivacySettings({ [key]: !e.target.checked } as Partial<T>)}
                    id={key}
                  />
                  <PrivacySettingsItemContent>
                    <PrivacySettingsItemTitle>{texts[key as keyof typeof texts].title}</PrivacySettingsItemTitle>
                    <PrivacySettingsItemDescription>
                      {texts[key as keyof typeof texts].description}
                    </PrivacySettingsItemDescription>
                  </PrivacySettingsItemContent>
                </PrivacySettingsItem>
              ))}
            </PrivacySettingsItemsList>
            <Button fullWidth label="Save" size="small" onClick={closeModal} />
          </PrivacySettingsModalContent>
        }
        isOpen={isSettingsModalOpen}
        onClose={closeModal}
      />
      {!isSettingsModalOpen && (
        <Wrapper>
          <GDPRContent>
            <h5>Your Privacy Matters</h5>
            <p>
              We use cookies and other tracking technologies to measure website traffic and improve your experience.{' '}
              <a target="_blank" href={`${process.env.NEXT_PUBLIC_ESIMPLUS_URL}/privacy`}>
                Privacy Policy
              </a>
            </p>
            <div>
              <CustomizeSettingsButton onClick={openModal}>Customize</CustomizeSettingsButton>
              <ButtonsWrapper>
                <Button
                  label="Accept"
                  size="small"
                  variant="secondary"
                  onClick={() => {
                    handleCookieConsent(privacySettings);
                  }}
                />
              </ButtonsWrapper>
            </div>
          </GDPRContent>
        </Wrapper>
      )}
    </>
  );
}

export { GDPR };
