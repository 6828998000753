'use client';

import React, { createContext, useEffect, useMemo, useState } from 'react';
import { isIOS } from '@/shared/utils';

const Context = createContext({
  isIOS: false
});

function Provider<T extends {}>(props: T) {
  const [operatingSystem, setOperatingSystem] = useState({ isIOS: isIOS() });

  useEffect(() => {
    setOperatingSystem({ isIOS: isIOS() })
  }, [])

  const value = useMemo(() => operatingSystem, [operatingSystem]);

  return <Context.Provider value={value} {...props} />;
}

function useOperatingSystem() {
  return React.useContext(Context);
}

export { Provider, useOperatingSystem, Context };
