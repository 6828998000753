export { default as FacebookIcon } from './facebook.svg';
export { default as TelegramIcon } from './telegram.svg';
export { default as LnIcon } from './ln.svg';
export { default as WhatWeAcceptIcon } from './what-we-accept.svg';
export { default as XMarkIcon } from './xmark.svg';
export { default as CheckIcon } from './check.svg';
export { default as MoonIcon } from './moon.svg';
export { default as SunIcon } from './sun.svg';
export { default as USDTIcon } from './usdt.svg';
export { default as CardIcon } from './card.svg';
export { default as CheckLiteIcon } from './check-lite.svg';
export { default as GlobeIcon } from './globe.svg';
export { default as TrustpilotStarIcon } from './trustpilot.svg';
export { default as StarIcon } from './star.svg';
export { default as AppleIcon } from './apple.svg';
export { default as GooglePlayIcon } from './google-play.svg';
export { default as TwentyFourSupportOutlinedIcon } from './24-support-outlined.svg';
export { default as CrossedOutCardIcon } from './crossed-out-card.svg';
export { default as CallCallingIcon } from './call-calling.svg';
export { default as SmsIcon } from './sms.svg';
export { default as GlobeOutlinedIcon } from './globe-outlined.svg';
export { default as CloudConnectionIcon } from './cloud-connection.svg';
export { default as GPlayIcon } from './gplay.svg';
export { default as GPlayBlackIcon } from './gplay-black.svg';
export { default as AppstoreIcon } from './appstore.svg';
export { default as AppstoreBlackIcon } from './appstore-black.svg';