export enum CookiesNames {
  Consent = 'cookie-consent',
  SDUser = 'sd-user',
  FreeNumbers = 'free-numbers',
}

export const APPSTORE_LINK = 'https://apps.apple.com/app/esim-mobile-data-cloud-sim/id1482736281';

export const GPLAY_LINK = 'https://play.google.com/store/apps/details?id=com.appvillis.esim&hl=ru&gl=US';

export const queryKeys = {
  GET_STARTED: 'get_started_type',
  MIXPANEL_DISTINCT_ID: 'distinct_id'
}

