const getTheme = (mode: 'light' | 'dark') => ({
  common: {
    white: '#FFFFFF',
    red: '#FF453A',
    neutralColor: '#999999',
    lightBlue: '#E0EAFD',
    blue: '#83B5F7',
    main: '#2253F5',
    trustpilot: '#64A979',
    gray: '#b2b2b2',
    orange: '#E5722F',
  },
  mode,
  ...(mode === 'light' && {
    primary: {
      text: '#000000',
      revertText: '#FFF',
      borderColor: 'rgba(0, 0, 0, 0.1)',
      bg: '#FFFFFF',
      htmlBg: '#ffffff',
      cardsBg: '#ffffff',
      navbarBg: '#ffffff',
      checkboxBorderColor: 'rgba(0, 0, 0, 0.5)',
      alertBg: 'rgba(217, 232, 252, 1)',
      modalBg: '#ffffff',
      crossBg: '#F0F0F0',
      ratingCardBg: '#F5F5F5',
      reviews: {
        border: 'rgba(0, 0, 0, 0.1)'
      },
      downloadApp: {
        bg: 'rgba(233, 236, 242, 0.5)'
      }
    },
    secondary: {
      text: '#666666',
      btnBg: 'transparent',
      btnBorder: '#F0F0F0',
    },
    tertiary: {
      text: '#333'
    }
  }),
  ...(mode === 'dark' && {
    primary: {
      text: '#FFF',
      revertText: '#000000',
      borderColor: 'rgba(255,255,255,0.1)',
      bg: 'radial-gradient(84.44% 84.68% at -3.96% -9.68%, #040D30 0%, #000006 100%)',
      htmlBg: '#000006',
      cardsBg: '#17171F',
      navbarBg: 'rgba(15,16,28,0.85)',
      checkboxBorderColor: 'rgba(237, 240, 250, 0.2)',
      alertBg: 'rgba(217, 232, 252, 0.3)',
      modalBg: 'rgba(255, 255, 255, 0.05)',
      crossBg: 'rgba(255, 255, 255, 0.1)',
      ratingCardBg: 'rgba(255, 255, 255, 0.1)',
      reviews: {
        border: 'rgba(255, 255, 255, 0.2)'
      },
      downloadApp: {
        bg: 'rgba(233, 236, 242, 0.15)'
      }
    },
    secondary: {
      text: 'rgba(255,255,255,0.65)',
      btnBg: 'rgba(255,255,255,0.1)',
      btnBorder: 'transparent',
    },
    tertiary: {
      text: 'rgba(255, 255, 255, 0.6)',
    }
  })
  ,
  breakpoints: {
    values: {
      xs: 350,
      sm: 768,
      md: 1024,
      lg: 1440,
    },
  }
});

export default getTheme;
