import React, { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { Title, Content, Wrapper, FullscreenWrapper } from './styled';

export interface ModalProps
  extends Omit<
    React.DetailedHTMLProps<React.HTMLAttributes<HTMLDialogElement>, HTMLDialogElement>,
    'title' | 'content'
  > {
  title: React.ReactNode;
  subtitle?: React.ReactNode;
  content: React.ReactNode;
  onClose: () => void;
  isOpen: boolean;
}

const modalRoot = typeof window !== 'undefined' && document.body;

const Modal = React.forwardRef<any, ModalProps>(({ title, subtitle, content, onClose, isOpen, ...props }, ref) => {
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true)
  }, [])

  if (!modalRoot || !mounted) {
    return null
  }

  return createPortal(
    <FullscreenWrapper $isOpen={isOpen}>
      <Wrapper ref={ref as any} {...props} open={isOpen}>
        <div>
          <Content>
            <Title>
              <div>
                <div>{title}</div>
                {subtitle && <div>{subtitle}</div>}
              </div>
              <button type="button" onClick={onClose}>
                <svg
                  fill="currentColor"
                  width="20px"
                  height="20px"
                  viewBox="0 0 32 32"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M18.8,16l5.5-5.5c0.8-0.8,0.8-2,0-2.8l0,0C24,7.3,23.5,7,23,7c-0.5,0-1,0.2-1.4,0.6L16,13.2l-5.5-5.5  c-0.8-0.8-2.1-0.8-2.8,0C7.3,8,7,8.5,7,9.1s0.2,1,0.6,1.4l5.5,5.5l-5.5,5.5C7.3,21.9,7,22.4,7,23c0,0.5,0.2,1,0.6,1.4  C8,24.8,8.5,25,9,25c0.5,0,1-0.2,1.4-0.6l5.5-5.5l5.5,5.5c0.8,0.8,2.1,0.8,2.8,0c0.8-0.8,0.8-2.1,0-2.8L18.8,16z" />
                </svg>
              </button>
            </Title>
            {content}
          </Content>
        </div>
      </Wrapper>
    </FullscreenWrapper>
    , modalRoot)
});

Modal.displayName = 'Modal';

export { Modal };
