import mixpanel, { OverridedMixpanel } from 'mixpanel-browser';

function getErrorMessage(error: any): string {
  let message = '';

  if (!error) {
    return message;
  }

  if (typeof error === 'string') {
    return error;
  }

  if (error?.details) {
    return error?.details;
  }
  if (error?.errors?.length) {
    Object.values(error?.errors as { [s: string]: unknown } | ArrayLike<unknown>).forEach((err: any) => {
      message += ` ${err?.message}\n`;
    });
  } else if (error?.message) {
    if (error.message.includes('Firebase')) {
      message = error.message.split('/')[1].replaceAll(/[(\-)]/g, ' ') as string;
    } else {
      message = error.message as string;
    }
  } else if (error?.errorMessage) {
    message = error.errorMessage as string;
  } else if (typeof error === 'object') {
    Object.values(error as { [s: string]: unknown } | ArrayLike<unknown>).forEach((err) => {
      message += ` ${err}\n`;
    });
  }

  if (message.startsWith('Unexpected JSON')) {
    return 'Server error.';
  }
  return message;
}

function setCookie(name: string, value: string, days: number) {
  if (typeof window === 'undefined') return;
  let expires = '';
  if (days) {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = `; expires=${date.toUTCString()}`;
  }
  document.cookie = `${name}=${value || ''}${expires}; domain=.esimplus.me; path=/`;
}

function getCookie(name: string) {
  if (typeof window === 'undefined') return;

  const nameEQ = `${name}=`;
  const ca = document.cookie.split(';');

  for (let i = 0; i < ca.length; i++) {
    let c = ca[i].trim();
    while (c.startsWith(' ')) c = c.substring(1, c.length);
    if (c.startsWith(nameEQ)) return c.substring(nameEQ.length, c.length);
  }
  return null;
}

function eraseCookie(name: string) {
  document.cookie = `${name}=; domain=.esimplus.me; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
}

function sendSafeEvent(type: 'gtag' | 'fbq', callback: () => void) {
  if (typeof window === 'undefined' || process.env.NEXT_PUBLIC_RUNTIME_ENV === 'development') {
    return;
  }

  if (typeof window[type as keyof typeof window] !== 'undefined') {
    callback();
    return;
  }

  let attempt = 0;
  const timerId = setInterval(() => {
    attempt += 1;

    if (attempt > 2) {
      clearInterval(timerId);
      return;
    }

    if (typeof window[type as keyof typeof window] !== 'undefined') {
      callback();
      clearInterval(timerId);
    } else {
      console.log(`${type} мetric not initialized`);
    }
  }, 500);

  return timerId;
}

function sendSafeFbqEvent(name: string, paramets?: object) {
  sendSafeEvent('fbq', () => window.fbq('track', name, paramets));
}

function sendSafeGtagEvent(name: string, paramets?: object) {
  sendSafeEvent('gtag', () => window.gtag('event', name, paramets));
}

function sendSafeMixpanelEvent<T extends keyof Omit<OverridedMixpanel, 'people'>>(
  method: T,
  ...params: Parameters<OverridedMixpanel[T]>
) {
  if (typeof window === 'undefined' || process.env.NEXT_PUBLIC_RUNTIME_ENV === 'development') {
    return;
  }

  // @ts-ignore 
  if (mixpanel.__loaded) {
    // @ts-ignore
    mixpanel[method](...params);
    return;
  }

  let attempt = 0;
  const timerId = setInterval(() => {
    attempt += 1;
    if (attempt > 3) {
      clearInterval(timerId);
      return;
    }

    // @ts-ignore
    if (mixpanel.__loaded) {
      // @ts-ignore
      mixpanel[method](...params);
      clearInterval(timerId);
    } else {
      console.log(`mixpanel not initialized`);
    }
  }, 1000);
}

function formatPathToReadableEventName(path: string) {
  const questionMarkIndex = path.indexOf('?');
  const hasQuery = questionMarkIndex !== -1;
  return path.slice(1, hasQuery ? questionMarkIndex : undefined).replaceAll('/', '-') || 'home';
}

const delay = (ms: number) =>
  new Promise((res) => {
    setTimeout(res, ms);
  });

function getCurrencySymbol(currency: string) {
  return (0)
    .toLocaleString('en-US', {
      style: 'currency',
      currency,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    })
    .replace(/\d/g, '')
    .trim();
}

function isIOS() {
  return (
    typeof window !== 'undefined' &&
    typeof window.navigator !== 'undefined' &&
    (window.navigator.platform.indexOf('Mac') === 0 ||
      ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(
        window.navigator.platform,
      ) ||
      (window.navigator.userAgent.includes('Mac') && 'ontouchend' in document))
  );
}

export {
  isIOS,
  getCurrencySymbol,
  delay,
  getErrorMessage,
  formatPathToReadableEventName,
  sendSafeGtagEvent,
  sendSafeFbqEvent,
  sendSafeMixpanelEvent,
  setCookie,
  getCookie,
  eraseCookie,
};
