'use client';

import React from 'react';

import { GDPR } from '@/widgets/GDPR/GDPR';
import { getCookie } from '@/shared/utils';
import { CookiesNames } from '@/shared/constants';

type PrivacySettingsConfig = { [key in PrivacySettings]: boolean };

type CookieConsentContextProps = PrivacySettingsConfig;

enum PrivacySettings {
  Analytics = 'analytics_cookies',
  Other = 'other_cookies',
}

const CookieConsentContext = React.createContext<CookieConsentContextProps>({
  [PrivacySettings.Analytics]: true,
  [PrivacySettings.Other]: true,
});

const cookieValue = getCookie(CookiesNames.Consent);

function CookieConsentProvider<T extends { children: React.ReactNode }>({ children, ...props }: T) {
  const [privacySettings, setPrivacySettings] = React.useState<PrivacySettingsConfig>({
    [PrivacySettings.Analytics]: cookieValue
      ? !!Number(new URLSearchParams(cookieValue).get(PrivacySettings.Analytics))
      : true,
    [PrivacySettings.Other]: cookieValue ? !!Number(new URLSearchParams(cookieValue).get(PrivacySettings.Other)) : true,
  });

  const value = React.useMemo(() => privacySettings, [privacySettings]);

  return (
    <CookieConsentContext.Provider value={value} {...props}>
      <GDPR
        initialSettings={privacySettings}
        onSubmit={(settings) => setPrivacySettings(settings as PrivacySettingsConfig)}
      />
      {children}
    </CookieConsentContext.Provider>
  );
}

function useCookieConsent() {
  return React.useContext(CookieConsentContext);
}

export { CookieConsentProvider, useCookieConsent, CookieConsentContext };
