'use client';

import React from 'react';
import { useCookieConsent } from '@/context/CookieConsentContext';
import { sendSafeGtagEvent, sendSafeMixpanelEvent, sendSafeFbqEvent } from '@/shared/utils';

enum AnalyticsServices {
  Google = 'gtag',
  Mixpanel = 'mixpanel',
  Meta = 'fbq',
}

type AnalyticsContextProps = {
  sendSafeMixpanelEvent: typeof sendSafeMixpanelEvent;
  sendSafeGtagEvent: typeof sendSafeGtagEvent;
  sendSafeFbqEvent: typeof sendSafeFbqEvent;
};

const AnalyticsContext = React.createContext<AnalyticsContextProps>({
  sendSafeMixpanelEvent,
  sendSafeGtagEvent,
  sendSafeFbqEvent,
});

function AnalyticsContextProvider<T extends {}>(props: T) {
  const cookieConsent = useCookieConsent();
  const [unsentEventsTuple, setUnsentEventsTuple] = React.useState<{ [k in AnalyticsServices]?: unknown[][] } | null>(
    null
  );

  React.useEffect(
    function sendAllUnsentEvents() {
      if (cookieConsent.analytics_cookies && unsentEventsTuple) {
        Object.entries(unsentEventsTuple).forEach(([serviceName, eventsList]) => {
          switch (serviceName as unknown as AnalyticsServices) {
            case AnalyticsServices.Google:
              eventsList.forEach((args) => sendSafeGtagEvent(...(args as Parameters<typeof sendSafeGtagEvent>)));
              break;
            case AnalyticsServices.Mixpanel:
              eventsList.forEach((args) =>
                sendSafeMixpanelEvent(...(args as Parameters<typeof sendSafeMixpanelEvent<any>>))
              );
              break;
            case AnalyticsServices.Meta:
              eventsList.forEach((args) => sendSafeFbqEvent(...(args as Parameters<typeof sendSafeFbqEvent>)));
              break;
            default:
              break;
          }
        });

        setUnsentEventsTuple(null);
      }
    },
    [cookieConsent.analytics_cookies, unsentEventsTuple]
  );

  function updateUnsentEventsList<A extends unknown[]>(args: A, type: AnalyticsServices) {
    setUnsentEventsTuple((prev) => ({ ...prev, [type]: [...(prev?.[type] ?? []), args] }));
  }

  const analyticsSendingFunctions = React.useMemo(() => {
    if (cookieConsent.analytics_cookies) {
      return {
        sendSafeMixpanelEvent,
        sendSafeGtagEvent,
        sendSafeFbqEvent,
      };
    }

    return {
      sendSafeMixpanelEvent: (...args: Parameters<typeof sendSafeMixpanelEvent<any>>) =>
        updateUnsentEventsList(args, AnalyticsServices.Mixpanel),
      sendSafeGtagEvent: (...args: Parameters<typeof sendSafeGtagEvent>) =>
        updateUnsentEventsList(args, AnalyticsServices.Google),
      sendSafeFbqEvent: (...args: Parameters<typeof sendSafeGtagEvent>) =>
        updateUnsentEventsList(args, AnalyticsServices.Meta),
    };
  }, [cookieConsent.analytics_cookies]);

  return <AnalyticsContext.Provider value={analyticsSendingFunctions} {...props} />;
}

function useAnalyticsContext() {
  return React.useContext(AnalyticsContext);
}

export { AnalyticsContextProvider, AnalyticsContext, useAnalyticsContext };
