'use client';

import React from 'react';
import mixpanel from 'mixpanel-browser';
import { usePathname, useSearchParams } from 'next/navigation';
import { useAnalyticsContext } from '@/context/AnalyticsContext';
import { formatPathToReadableEventName } from '@/shared/utils';
import { MixpanelPageContext } from './types';

const Context = React.createContext<MixpanelPageContext>({ source: '', utmString: '', mixpanelDistinctId: '' });

export const usePageContext = () => React.useContext(Context);

export function PageProvider(props: React.PropsWithChildren) {
  const { children } = props;
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const { sendSafeMixpanelEvent } = useAnalyticsContext();
  const source = formatPathToReadableEventName(pathname);
  const [utmString, setUtmString] = React.useState('');
  const [mixpanelDistinctId, setMixpanelDistinctId] = React.useState('');

  React.useEffect(() => {
    mixpanel.init(process.env.NEXT_PUBLIC_MIXPANEL_TOKEN!, {
      debug: process.env.NEXT_PUBLIC_RUNTIME_ENV !== 'development',
      persistence: 'localStorage',
    });

    let distinctId = mixpanel.get_distinct_id() as string;

    if (distinctId.startsWith('$device:')) {
      distinctId = distinctId.replace('$device:', '');
      mixpanel.identify(distinctId);
    }

    setMixpanelDistinctId(distinctId);
  }, []);

  React.useEffect(() => {
    const searchParamsString = searchParams.toString();

    if (searchParamsString.length > 0) {
      setUtmString(
        searchParamsString
          .split('&')
          .filter((param) => param.includes('utm_'))
          .join('&')
      );
    }
  }, []);

  React.useEffect(() => {
    if (typeof window !== 'undefined') {
      sendSafeMixpanelEvent('track_pageview', { source });
    }
  }, [source, sendSafeMixpanelEvent]);

  const value = React.useMemo(
    () => ({ source, utmString, mixpanelDistinctId }),
    [source, utmString, mixpanelDistinctId]
  );

  return <Context.Provider value={value}>{children}</Context.Provider>;
}
